@font-face {
  font-family: Campton-light;
  src: url("/assets/campton-light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Campton-medium;
  src: url("/assets/campton-medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Campton-bold;
  src: url("/assets/campton-bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter-light;
  src: url("/assets/inter-light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter-regular;
  src: url("/assets/inter-regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter-semibold;
  src: url("/assets/inter-semibold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Monosten-light;
  src: url("/assets/monosten-light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Monosten-regular;
  src: url("/assets/monosten-regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Monosten-bold;
  src: url("/assets/monosten-bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter-Medium;
  src: url("/assets/inter-medium.woff2") format("woff2");
  font-display: swap;
}

/* Force Next and Storybook generated DOM elements to fill their parent's height */
#__next,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html,
body {
  height: 100%;
}
